import React from 'react';
import { element } from 'prop-types';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Login from '@pages/login';
import Home from '@pages/home';
import NotFound from '@pages/notFound';
import NccPage from '@pages/ncc';
import UserTemplate from '@components/templates/user';
import { ProvideAuth, useAuth } from '@store/auth/hook';
import SetUpMFAPage from '@pages/setupMFA';
const PrivateRoute = ({ children, ...rest }) => {
  let auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        let component;

        if (auth.user && auth.user.isMFAActive) {
          if (location.pathname === '/setup-mfa') {
            component = (
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: location },
                }}
              />
            );
          } else {
            component = children;
          }
        } else if (auth.user && !auth.user.isMFAActive) {
          if (location.pathname !== '/setup-mfa') {
            component = (
              <Redirect
                to={{
                  pathname: '/setup-mfa',
                  state: { from: location },
                }}
              />
            );
          } else {
            component = children;
          }
        } else {
          component = (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }
        return component;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: element.isRequired,
};

const RootRouter = () => {
  return (
    <Router>
      <ProvideAuth>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/ncc/:id">
            <NccPage />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          {/* <PrivateRoute path="/first-password">
            <FirstPassword />
          </PrivateRoute> */}
          <PrivateRoute path="/setup-mfa">
            <SetUpMFAPage />
          </PrivateRoute>
          <PrivateRoute path="/dashboard">
            <UserTemplate />
          </PrivateRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </ProvideAuth>
    </Router>
  );
};

export default RootRouter;
